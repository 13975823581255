@import './styleguide/index.scss';
@import './layout/index.scss';

#basic-page-extended, #basic-page-content {
  width: 100vw;
  height: 100vh;
}

#basic-page-extended-logo-big{
  margin-top: var(--margin-huge);
  margin-bottom: var(--margin-medium);
  height: 202px;
}

#basic-page-extended-picto{
  margin-top: var(--margin-medium);
  height: 586px;
  margin-bottom: var(--margin-medium);
}

.basic-page-extended-text{
  font-size: var(--font-large);
  height: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: var(--appear);
}

#video-container {
  position: relative;
  height: 726px;
}

.basic-page-extended-video {
  position: absolute;
  margin-top: var(--margin-medium);
  margin-bottom: var(--margin-small);
  width: 860px;
  height: 586px;
  padding: var(--padding-medium);
  background-color: transparent;
  border: 3px solid white;
  border-radius: 40px;
  animation: var(--appear);
  left: 50%;
  margin-left: -430px;
  animation: var(--appear);
}

.disappear {
  animation: var(--disappear);
}