
:root {
  --padding-extra-tiny: 4px;
  --padding-tiny: 8px;
  --padding-small: 16px;
  --padding-medium: 20px;
  --padding-large: 40px;
  --padding-huge: 60px;
  --padding-gigantic: 100px;
}
