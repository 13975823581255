
:root {
  --margin-extra-tiny: 12px;
  --margin-tiny: 24px;
  --margin-small: 44px;
  --margin-medium: 96px;
  --margin-large: 124px;
  --margin-huge: 186px;
  --margin-gigantic: 202px;
}
